import React from 'react'

import Layout from './Layout.js'

import Block from '../Block'
import Slider from './Slider.js'

// import Card from './Card.js'

import './stnixe.css'

export default function StNixe() {
	return (
		<Layout cls="stnixe">
			<Slider />
			<div className="container">
				<Block cls="" title="">
					<div className="align-center">Em Breve</div>
				</Block>
			</div>
		</Layout>
	);
}
