import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import StNixe from "./pages/stnixe/StNixe";
import ReitekinaHouseki from "./pages/reitekinahouseki/ReitekinaHouseki";
import ShikigamiProject from "./pages/shikigamiproject/ShikigamiProject";

import Trabalhos from "./pages/stnixe/Trabalhos";
import Noticias from "./pages/stnixe/Noticias";
import Loja from "./pages/stnixe/Loja";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/"><StNixe /></Route>
          <Route path="/reitekinahouseki"><ReitekinaHouseki /></Route>
          <Route path="/shikigamiproject"><ShikigamiProject /></Route>
          <Route path="/trabalhos">
            <Trabalhos />
          </Route>
          <Route path="/noticias">
            <Noticias />
          </Route>
          <Route path="/loja">
            <Loja />
          </Route>
          <Route path="*">
            Erro 404
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
