import React from 'react'

export default class Block extends React.Component {
	// constructor(props) {
	// 	// super(props)
	// }

	bloco() {
		if (this.props.title) {
			return (
				<>
					<span className="block-title">{this.props.title}</span>
					{/* <div className={`${this.props.cls ? "block-content " + this.props.cls : "block"}`}> */}
					<div className={`block-content ${this.props.cls}`}>
						{this.props.children}
					</div>
				</>
			)
		} else {
			return this.props.children
		}
	}

	render() {
		return (
			<div className={`block ${this.props.cls}`}>
				{ this.bloco() }
			</div>
		)
	}
}
