import React from 'react'
import { Link, useRouteMatch } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import logo from '../../img/logo-nixe-linha.png'

export default function Layout (props) {
	let { path } = useRouteMatch();

	return (
		<div className={props.cls}>
			{/* NAV */}
			<nav className="navbar-shikigami" >
				<div className="nav-item">
					<Link className="nav-link" to="/shikigamiproject">Shikigami Project</Link>
				</div>
				<div className="nav-item">
					<Link className="nav-link" to={`${path}/`}>Personagens</Link>
					<Link className="nav-link" to={`${path}/Historia`}>Personagens</Link>
					<Link className="nav-link" to="/loja">Loja</Link>
				</div>
			</nav>
			{/* MAIN */}
			<main>{props.children}</main>
			{/* FOOTER */}
			<footer className="ftr">
				<nav>
					<a href="https://www.facebook.com/StudioNixe" target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={["fab", "facebook-f"]} />
					</a>
					{/*
					<a href="https://www.twitter.com/StNixe" target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={["fab", "twitter"]} />
					</a>
					<a href="https://www.instagram.com/StNixe" target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={["fab", "instagram"]} />
					</a>
					*/}
					<a href="https://www.twitch.tv/StNixe" target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={["fab", "twitch"]} />
					</a>
				</nav>
				<span>&copy; Nixë Studio - Todos os direitos reservados</span>
			</footer>
		</div>
	);
}