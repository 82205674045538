import React from 'react';

import Block from '../Block.jsx'

import logo from '../../img/logo-nixe.png'

export default function Slider () {
	return (
		<Block cls="slider">
			<img src={logo} alt="" />
		</Block>
	);
}