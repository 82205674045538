import React from "react";

import banner from '../../img/capa-3.png'

export default function Banner () {
    return (
        <div className="banner">
            <img src={banner} alt="" />
        </div>
    )
}