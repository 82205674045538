import React from 'react'

import Layout from './Layout.js'

import Block from '../Block.jsx'

export default function Loja () {
	return (
		<Layout cls="stnixe">
			<Block cls="" title="">
				<div className="align-center">Em Breve</div>
			</Block>
		</Layout>
	)
}