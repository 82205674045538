import React from 'react'

import Layout from './Layout'
import Banner from './Banner'

import Block from '../Block'

import './shikigamiproject.css'

export default function ShikigamiProject() {
	return (
		<Layout cls="shikigamiproject">
		<Banner />
		<div className="container">
			<Block cls="card-align">
				[navbar Nixe]
				[SLIDER]
				[MAIN]
				[FOOTER]
			</Block>
		</div>
		</Layout>
	);
}