import React from 'react'

import Layout from './Layout.js'

import Block from '../Block'
// import Card from './Card.js'

export default function Trabalhos() {
	return (
		<Layout cls="stnixe">
			<div className="container">
				<Block cls="" title="">
					<div className="align-center">Em Breve</div>
				</Block>
			</div>
		</Layout>
	)
}